const p5 = require('p5');
const vert = require('./depth.vert');
const frag = require('./depth.frag');

module.exports = ({ tileW, tileH, w, h, vx, vy, client, qs, considerShader }) =>
  new Promise((resolve, reject) => {
    let sat, depth, merged, shader, quilt, merged2, shader2;

    const s = p => {
      p.setup = () => {
        p.noCanvas();
        if (client) p.noLoop();

        sat = p.createGraphics(tileW, tileH);

        depth = p.createGraphics(tileW, tileH);

        merged = p.createGraphics(tileW, tileH, p.WEBGL);
        merged.noStroke();
        shader = merged.createShader(vert, frag);
        merged.shader(shader);

        if (client) {
          quilt = p.createGraphics(w, h);
          quilt.background(60);
          quilt.fill(255);
          quilt.textAlign(p.CENTER, p.CENTER);
          quilt.textSize(0.05 * Math.sqrt(tileW * tileH));
          for (let y = 0; y < vy; y++) {
            for (let x = 0; x < vx; x++) {
              const quiltX = x * tileW;
              const quiltY = y * tileH;
              quilt.text('LOADING...', quiltX + tileW / 2, quiltY + tileH / 2);
            }
          }
        } else {
          merged.show();
          qs('#eyes').appendChild(merged.elt);

          merged2 = p.createGraphics(tileW, tileH, p.WEBGL);
          merged2.noStroke();
          qs('#eyes').appendChild(merged2.elt);
          shader2 = merged2.createShader(vert, frag);
          merged2.shader(shader2);
        }
        resolve({ p, sat, depth, merged, shader, quilt, merged2, shader2 });
      };
      p.draw = () => considerShader(true);
    };

    new p5(s);
  });
