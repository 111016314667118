module.exports = [
  {
    name: 'Abraham Lake, Canada',
    zoom: 10.214858455054612,
    center: [-116.42827094027804, 52.1801923984049]
  },
  {
    name: 'Monument Valley, USA',
    zoom: 9.940305442696738,
    center: [-110.76380273867696, 37.323377659732856]
  },
  {
    name: 'Milford Sound, New Zealand',
    zoom: 9.676561616638828,
    center: [167.87740612409345, -44.65752382556623]
  },
  {
    name: 'Badain Jaran Dunes, China',
    zoom: 10.621000103922823,
    center: [102.20178919148464, 39.93147636443146]
  },
  {
    name: 'Uluru, Australia',
    zoom: 12.806871170442195,
    center: [131.03754062399184, -25.345581882644026]
  },
  {
    name: 'Pulpit Rock, Norway',
    zoom: 9.578554518556704,
    center: [6.590273958459875, 59.04432220812498]
  },
  {
    name: 'Horn of Africa and Arabian Peninsula',
    zoom: 4.001296645612922,
    center: [41.6983308934486, 15.255605735554298]
  },
  {
    name: 'Caspian Sea',
    zoom: 4.241662001326076,
    center: [50.80378410109063, 41.91876393318228]
  },
  {
    name: 'Victoria Falls, Zambia-Zimbabwe',
    zoom: 12.23509997678085,
    center: [25.854120570893343, -17.937666997969416]
  },
  {
    name: 'Vestrahorn, Iceland',
    zoom: 8.775596539473133,
    center: [-15.190553034359112, 64.39694250816055]
  },
  {
    name: 'Nevado de Toluca, Mexico',
    zoom: 12.607528782363314,
    center: [-99.75821373667556, 19.10980102454235]
  },
  {
    name: 'Grand Canyon, USA',
    zoom: 10.264416952018522,
    center: [-111.88609151371008, 36.38053732919121]
  },
  {
    name: 'Orbaneja del Castillo, Spain',
    zoom: 12.069425032216136,
    center: [-3.7921062615010896, 42.830472881298846]
  },
  {
    name: 'Mt. Thor, Canada',
    zoom: 11.179570482523163,
    center: [-65.31770487239089, 66.53019765308898]
  },
  {
    name: 'Santa María, Argentina',
    zoom: 7.652568144906178,
    center: [-65.9974728933183, -26.641076401955168]
  },
  {
    name: 'Kauai, USA',
    zoom: 11.425992802841444,
    center: [-159.39173554046644, 21.944079722045757]
  }
  // {
  //   name: 'Nevados de Payachata, Chile-Bolivia',
  //   zoom: 9.143697720293924,
  //   center: [-69.04301885673607, -18.19569819950273]
  // },
  // {
  //   name: 'Mt. Vesiuvius, Italy',
  //   zoom: 11.344185044219424,
  //   center: [14.427070906190238, 40.82440730776324]
  // }
  // {
  //   name: 'El Caminito del Rey, Spain',
  //   zoom: 13.394351247727407,
  //   center: [-4.8008891114070025, 36.92940556350966]
  // },
  //,{
  //   name: 'Fjords of Norway',
  //   zoom: 7.221061103577478,
  //   center: [6.979046828442279, 62.59055614861862]
  // },
  // {
  //   name: 'The Pitons, St. Lucia',
  //   zoom: 12.008145137605078,
  //   center: [-61.05930095448291, 13.814189434440507]
  // },
  // {
  //   name: 'Three Gorges, China',
  //   zoom: 10.420773081872868,
  //   center: [110.71965497131964, 30.97076962998213]
  // },
  // {
  //   name: 'Mt. Everest, China-Nepal',
  //   zoom: 8.398432270747586,
  //   center: [86.9209363631287, 27.914599220911896]
  // },
];
