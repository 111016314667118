import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

module.exports = ({ tileW, tileH, defaults, keys }) => {
  const { zoom, center } =
    defaults[Math.floor(Math.random() * defaults.length)];
  mapboxgl.accessToken = keys.mapbox;

  ['satelliteMap', 'depthMap', 'previewMap'].forEach(k => {
    const elt = document.getElementById(k);
    if (elt) {
      elt.setAttribute(
        'style',
        `width:${tileW}px; height:${tileH}px; min-width:${tileW}px; min-height:${tileH}px`
      );
    }
  });

  const result = {
    prev: {
      ready: true,
      map: new mapboxgl.Map({
        container: 'previewMap',
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center,
        zoom,
        maxZoom: 14,
        boxZoom: false,
        dragRotate: false,
        touchPitch: false,
        trackResize: false
      })
    },
    sat: {
      ready: false,
      map: new mapboxgl.Map({
        container: 'satelliteMap',
        style: 'mapbox://styles/mapbox/satellite-v9',
        center,
        zoom,
        interactive: false,
        trackResize: false,
        preserveDrawingBuffer: true
      })
    },
    depth: {
      ready: false,
      map: new mapboxgl.Map({
        container: 'depthMap',
        style: {
          version: 8,
          name: 'Custom depth tiles',
          layers: [
            {
              id: 'water',
              type: 'raster',
              source: 'depth-tiles'
            }
          ],
          sources: {
            'depth-tiles': {
              type: 'raster',
              // alternative (fails on retina) url: 'mapbox://mapbox.mapbox-terrain-dem-v1'
              url: 'mapbox://mapbox.terrain-rgb'
            }
          }
        },
        center,
        zoom,
        interactive: false,
        trackResize: false,
        preserveDrawingBuffer: true
      })
    }
  };

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl,
    marker: false,
    flyTo: {
      duration: 0.1
    },
    collapsed: true
  });

  result.prev.map.touchZoomRotate.disableRotation();
  result.prev.map.addControl(geocoder);

  ['depthMap', 'satelliteMap'].forEach(k => {
    document.getElementById(k).style.display = 'none';
  });

  return result;
};
