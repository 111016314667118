#ifdef GL_ES
precision highp float;
  #endif

varying vec2 vTexCoord;

uniform sampler2D uDepthSource;
uniform sampler2D uSatSource;
uniform float uAmount;
uniform float uExaggerate;
uniform float uBase;
uniform bool uAnaglyph;

void main() {
    vec4 depthColor = texture2D(uDepthSource, vTexCoord);

    float meters = -10000.0 + (depthColor.r * 255.0 * 256.0 * 256.0 + depthColor.g * 255.0 * 256.0 + depthColor.b * 255.0) * 0.1;
    meters = meters - uBase;

    vec2 disp = vec2(uAmount * uExaggerate * meters, 0.0);

    vec4 result = texture2D(uSatSource, vTexCoord + disp);

    if(uAnaglyph) {
        vec4 result2 = texture2D(uSatSource, vTexCoord - disp);
        gl_FragColor = vec4(result2.r, result.gba);
    } else {
        gl_FragColor = result;
    }
}