const {
  ShowMessage,
  CacheMessage,
  ShowCachedMessage,
  Client
} = require('holoplay-core');

const holoErrors = require('./holoErrors.js');

const cache = {};

const getPNG = (quilt, proof) =>
  new Promise(resolve =>
    quilt.elt.toBlob(
      async blob =>
        resolve([Buffer.from(await blob.arrayBuffer(), 'base64'), proof]),
      'image/png'
    )
  );

const showQuilt = async ({ quilt, client, specs, proof, onError }) => {
  if (proof === cache.proof) {
    const [png, check] = await getPNG(quilt, proof);
    if (check === cache.proof) {
      client
        .sendMessage(new ShowMessage(specs, png))
        .catch(e => onError(holoErrors[e.error]));
    }
  }
};

const setLoading = async ({ quilt, client, specs }) =>
  new Promise(async (resolve, reject) => {
    const [png] = await getPNG(quilt);
    client
      .sendMessage(new CacheMessage('loading', specs, png), true)
      .then(resolve)
      .catch(resolve);
  });

const promiseHoloPlayCore = () =>
  new Promise((resolve, reject) => {
    const client = new Client(
      ({ devices }) => {
        const device = devices[0];
        if (!device) return reject('Device not found');
        if (!device.defaultQuilt) return reject('Device calibration not found');

        resolve([client, device]);
      },
      e => {
        console.error(e);
        reject(holoErrors[e.error]);
      }
    );
  });

const showLoading = ({ client, specs, proof, avoidLoading }) => {
  cache.proof = proof;
  if (!avoidLoading) {
    client
      .sendMessage(new ShowCachedMessage('loading', 0, specs))
      .catch(() => {});
  }
};

module.exports = {
  promiseHoloPlayCore,
  showQuilt,
  setLoading,
  showLoading
};
