import { saveAs } from 'file-saver';

const createSketch = require('./createSketch');
const keys = require('./keys.js');
const defaults = require('./defaults.js');
const makeMaps = require('./makeMaps.js');
const holo = require('./holoplayHandler.js');

const run = async () => {
  const qs = document.querySelector.bind(document);
  const qsa = document.querySelectorAll.bind(document);
  const ctn = document.createTextNode.bind(document);
  const ce = document.createElement.bind(document);

  const connectedHolo = await holo.promiseHoloPlayCore().catch(m => {
    console.error(m);
    qs('#download').style.display = 'none';
    qs('#weather').style.display = 'none';
    qs('#error').style.display = 'block';
    qs('#error span').textContent = '';
    qs('#error span').appendChild(ctn('Could not connect to '));
    const a = ce('a');
    const linkText = ctn('Looking Glass');
    a.title = 'Looking Glass Factory';
    a.href = 'https://lookingglassfactory.com/';
    a.setAttribute('target', '_blank');
    a.setAttribute('rel', 'noopener noreferrer');
    a.appendChild(linkText);
    qs('#error span').appendChild(a);
    qs('#error span').appendChild(ctn(' device. Use viewing methods below or'));
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      qs('#error').appendChild(ctn('. Safari browser not recommended.'));
    }
  });

  const [client, device] = connectedHolo || [];

  let aspect, w, h, vx, vy;

  if (device) {
    qs('#tooSmall').remove();
    const { quiltAspect, quiltX, quiltY, tileX, tileY } =
      typeof device.defaultQuilt === 'string'
        ? JSON.parse(device.defaultQuilt)
        : device.defaultQuilt;
    aspect = quiltAspect;
    w = quiltX;
    h = quiltY;
    vx = tileX;
    vy = tileY;
  } else {
    [aspect, w, h, vx, vy] = [0.75, 405, 540, 1, 1];
  }

  if (!client) qs('#modes').style.display = 'block';

  const vtotal = vx * vy;
  const specs = { vx, vy, vtotal, aspect };

  const tileW = w / vx;
  const tileH = h / vy;

  const maps = makeMaps({ tileW, tileH, keys, defaults });

  maps.prev.map.on('movestart', e => {
    maps.prev.ready = false;
  });

  maps.prev.map.on('moveend', e => {
    const zoom = maps.prev.map.getZoom();
    const center = maps.prev.map.getCenter();
    maps.prev.ready = true;
    // console.log({ zoom, center: [center.lng, center.lat] });
    ['depth', 'sat'].forEach(k => {
      if (maps[k]) {
        maps[k].ready = false;
        maps[k].map.setZoom(zoom);
        maps[k].map.setCenter(center);
      }
    });
    considerShader();
  });

  const highlights = qs('#highlights');
  defaults.forEach((d, i, { length }) => {
    const a = ce('a');
    const linkText = ctn(d.name);
    a.appendChild(linkText);
    a.title = d.name;
    a.href = '#';
    a.addEventListener('click', () => {
      maps.prev.map.setCenter(d.center);
      maps.prev.map.setZoom(d.zoom);
    });
    highlights.appendChild(a);
    if (i + 1 < length) highlights.appendChild(ce('br'));
  });

  const sketch = {};

  const considerShader = (looping, avoidLoading) => {
    const proof = Date.now() + Math.random();
    if (client) {
      holo.showLoading({ client, specs, avoidLoading, proof });
    }
    if (maps.sat.ready && maps.depth.ready && sketch.ready) {
      const { p, sat, depth, merged, shader, quilt, merged2, shader2 } =
        sketch.sketch;

      const mode = client ? undefined : qs('input[name="mode"]:checked').value;

      if (!looping) {
        const satCanvas = maps.sat.map.getCanvas();
        sat.drawingContext.drawImage(satCanvas, 0, 0, tileW, tileH);

        const depthCanvas = maps.depth.map.getCanvas();
        depth.drawingContext.drawImage(depthCanvas, 0, 0, tileW, tileH);

        depth.loadPixels();
        let min = Infinity;
        let max = -Infinity;
        let avg = 0;
        const alts = {};
        for (let i = 0; i < depth.pixels.length; i += 4) {
          const [r, g, b] = depth.pixels.slice(i, i + 3);
          const alt = -10000 + (r * 256 * 256 + g * 256 + b) * 0.1;
          min = Math.min(min, alt);
          max = Math.max(max, alt);
          avg += alt;
          alts[alt] = (alts[alt] || 0) + 1;
        }
        avg /= depth.pixels.length / 4;

        const auto = qs('#auto').checked;
        let depthAmount;
        if (auto) {
          depthAmount = 1 + 14 * ((maps.prev.map.getZoom() - 0.5) / (14 - 0.5));
          const maxDiff = depthAmount * Math.max(max - avg, avg - min);
          const limitDiff = 9000;
          if (maxDiff > limitDiff) {
            depthAmount = limitDiff / Math.max(max - avg, avg - min);
          }
        } else depthAmount = +qs('#amount').value;

        shader.setUniform('uDepthSource', depth);
        shader.setUniform('uSatSource', sat);
        shader.setUniform('uAnaglyph', false);
        shader.setUniform('uExaggerate', depthAmount / 100000);
        shader.setUniform('uBase', avg);

        if (mode === 'parallel' || mode === 'crosseye') {
          shader2.setUniform('uDepthSource', depth);
          shader2.setUniform('uSatSource', sat);
          shader2.setUniform('uAnaglyph', false);
          shader2.setUniform('uExaggerate', depthAmount / 100000);
          shader2.setUniform('uBase', avg);
        }
      }

      if (client) {
        for (let y = 0; y < vy; y++) {
          for (let x = 0; x < vx; x++) {
            const i = y * vx + x;
            const propScale = -1 + (2 * i) / (vtotal - 1);
            const quiltX = x * tileW;
            const quiltY = tileH * vy - (y + 1) * tileH;
            shader.setUniform('uAmount', propScale);
            merged.rect(0, 0, 0, 0);
            quilt.image(merged, quiltX, quiltY);
          }
        }

        const onError = m => {
          qs('#error').style.display = 'block';
          qs('#error span').textContent = m;
        };
        holo.showQuilt({ quilt, client, specs, proof, onError });
      } else {
        let propScale;
        if (mode === 'animated') {
          if (Object.keys(maps).every(k => maps[k].ready)) {
            propScale = Math.sin(p.millis() / 300) * 0.2;
            shader.setUniform('uAmount', propScale);
            merged.rect(0, 0, 0, 0);
          }
        } else if (mode === 'parallel' || mode === 'crosseye') {
          propScale = mode === 'parallel' ? -0.2 : 0.2;
          shader.setUniform('uAmount', propScale);
          merged.rect(0, 0, 0, 0);

          propScale *= -1;
          shader2.setUniform('uAmount', propScale);
          merged2.rect(0, 0, 0, 0);
        } else if (mode === 'anaglyph') {
          propScale = 0.2;
          shader.setUniform('uAmount', propScale);
          shader.setUniform('uAnaglyph', true);
          merged.rect(0, 0, 0, 0);
        }
      }
    }
  };

  if (client) setInterval(() => considerShader(true, true), 5000);

  maps.prev.map.on('idle', () => {
    qs('#loading').style.display = 'none';
  });

  maps.depth.map.on('idle', () => {
    maps.depth.ready = true;
    considerShader();
  });

  maps.sat.map.on('idle', () => {
    maps.sat.ready = true;
    considerShader();
  });

  qs('#amount').addEventListener('change', () => considerShader());
  qs('#auto').addEventListener('change', e => {
    qs('#amount').disabled = e.target.checked;
    considerShader();
  });
  qs('#map').addEventListener('change', e => {
    maps.sat.map.setStyle(e.target.value);
  });

  sketch.sketch = await createSketch({
    tileW,
    tileH,
    w,
    h,
    vx,
    vy,
    client,
    qs,
    considerShader
  });

  const { p, quilt, merged2 } = sketch.sketch;

  const modes = qsa('input[name="mode"]');
  for (const mode of modes) {
    mode.addEventListener('change', e => {
      if (e.target.value === 'animated') {
        p.loop();
        merged2.hide();
      } else if (e.target.value === 'anaglyph') {
        p.noLoop();
        merged2.hide();
      } else {
        p.noLoop();
        merged2.show();
      }
      considerShader();
    });
  }

  if (client) {
    holo.setLoading({ quilt, client, specs }).then(() => {
      sketch.ready = true;
      considerShader();
    });
    qs('#download').addEventListener('click', () => {
      quilt.elt.toBlob(blob => {
        const latlon = maps.prev.map.getCenter();
        saveAs(blob, `${latlon.lat}_${latlon.lng}_qs${vx}x${vy}a${aspect}.png`);
      });
    });
  } else sketch.ready = true;
};

run();
